#wiki-search {
    margin: 0 auto;
    max-width: 340px;
}

#wiki-recs {
    background-color: white;
}

.wiki-rec {
    padding: 10px;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}

.wiki-rec:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

#wiki-input {
    font-size: 16px;
    width: 318px;
    margin: 0;
    border: 1px solid black;
    padding: 10px;
}

#wiki-input:focus {
    border: 2px solid black;
    width: 316px;
}
